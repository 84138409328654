import React from "react";
import { graphql } from "gatsby";
import PageWithCmsWrapper from "../components/shared/page-with-cms-wrapper";

const EmailConfirmation = ({
                             path,
                             data: {
                               strapiEmailConfirmation: {
                                 Title,
                                 Content,
                                 SeoTitle,
                                 MetaDescriptions,
                               },
                             },
                           }) => {
  return (
    <PageWithCmsWrapper
      path={path}
      title={Title}
      content={Content}
      seoProps={{ title: SeoTitle, description: MetaDescriptions}}
    />
  );
};


export default EmailConfirmation;

export const query = graphql`
  query EmailConfirmationQuery {
    strapiEmailConfirmation {
      Title
      Content
      SeoTitle
      MetaDescriptions
    }
  }
`;
